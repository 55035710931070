@import 'core-styles/variables';


.otp-item {
  margin-bottom: 30px !important;

  .ant-form-item-children {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input {
    &.otp {
      width: 54px !important;
      padding: 0 !important;
      text-align: center;
      margin: 0 20px;
      outline-color: $primary-color;

      &:focus {
        background: #fafafa;
      }
    }
  }
}

.otp-input {
  width: 54px !important;
  padding: 0 !important;
  text-align: center;
  margin-right: 10px;
  outline-color: $primary-color;

  &.ant-input-number {
    input {
      padding-left: 10px;
      text-align: center;
      font-weight: bold;
    }
  }

  &:focus {
    background: #fafafa;
  }
}

/* responsiveness */
@media (max-width: 992px) {}

@media (max-width: 762px) {}

@media (max-width: 520px) {}

/* /responsiveness */